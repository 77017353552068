import React, {useMemo, useEffect, useState} from "react";
import { useUILang } from "../../AppExPf/utils/useUILang";
import { toIdStr, toUniIdAry } from "../../consts/ATValidate";
import CpDropdown, { ent2DropdownItem, obj2DropdownItem } from "../_components/CpDropdown";
import { isAry, isObj, objEntries, objKeys, objMapObj, strCmp, toAry, toObj, toStr } from "../../libs/libType";
import { stopEvent } from "../../libs/libUI";
import { aTErrDiv1, preJS } from "../../AppExPf/AppUtil";
import CpCheckbox from "../_components/CpCheckbox";
import { CpSettingModalSection, CpSettingModalTitle
    ,CpSettingModalTabContainer, CpSettingModalSubTitle } from "../_components/CpSettingModalTabCps";

export const subjMetaCombo = (subjMetas, ct) => {
    const ret =  objMapObj(subjMetas, ([i, s]) => [s.metaSetId+','+s.metaId, {t:ct(s.nameEn, s.nameCt)}]);
    //const ret = ent2DropdownItem(toAry(subjMetas).map(s => [s.metaSetId+','+s.metaId, ct(s.nameEn, s.nameCt)]));
    return ret;
};

export const useYearClassItems = (yearClass2d, uiEn, ut) => useMemo(() => {
    const YEntSort = objEntries(yearClass2d).sort(([i, a], [j, b]) => strCmp(j, i));

    const YC2d = YEntSort.map(([y, classs]) => {
        const cs = objEntries(classs).sort(([i, c], [j, d]) => strCmp(c.nameEng, d.nameEng) );
        return cs.map(([i, c]) => [i, y + ' ('+ ut(c.nameEng, c.nameChi) + ')', y, c ] );
    });
    
    const flatcs = [].concat(...YC2d);
    const ymap = Object.fromEntries(flatcs.map(r => [r[0], r[2]]));
    const clsMap = Object.fromEntries(flatcs.map(r => [r[0], r[3]]));
    return [ent2DropdownItem(flatcs), ymap, clsMap];  
}, [yearClass2d, uiEn]);

export const useYearItems = yearClass2d => useMemo(() => ent2DropdownItem(objKeys(yearClass2d).sort((a, b) => strCmp(b, a)).map(y => [y, y])), [yearClass2d]);

export const useClassItems = (classs, uiEn, ut) => useMemo(() => obj2DropdownItem( Object.fromEntries( objEntries(classs)
    .sort(([i, c], [j, d]) => strCmp(c.nameEng, d.nameEng) )
    .map(([i, c]) => [i, ut(c.nameEng, c.nameChi)])
)), [classs, uiEn]);


export const useYearClass = (_yearClass2d, y, c, uiEn, ut, isThr) => {
    const yearClass2d = toObj(_yearClass2d);

    const [yearClassDDI, ymap, clsMap] = useYearClassItems(yearClass2d, uiEn, ut);

    const yearItems = useYearItems(yearClass2d);
    const year = yearClass2d[y]? y: objKeys(yearItems)[0];   
 
    const classs = toObj(yearClass2d[year]);
    const classItems = useClassItems(classs, uiEn, ut);

    const classId = isThr 
        ?(classs[c]? c: objKeys(classItems)[0])
        :(yearClassDDI[c])? c: objKeys(yearClassDDI)[0];   

    return [yearItems, year, classItems, classId, yearClassDDI, ymap];
};

const TabAssignmentSettingClass = (props => { // base on poc AssignmentSettingClass
    const {vis, fields, setFields, setField, opts, setOpts, fieldErrs} = props;
    const [t, uiEn, UILang, setUILang, ut] = useUILang();

    const [y, c] = [toStr(fields?.year), toStr(fields?.classId)];
    const yc2d = opts?.yearClass2d;
    const [yearItems, year, classItems, classId] = useYearClass(yc2d, y, c, uiEn, ut, 1);
    useEffect(() => { 
        
        if(yc2d && ((year !== y) || (classId !== c))) setFields(fs => ( {...toObj(fs), year, classId} ) );
    }, [yc2d, year, classId]);

    const optStudents = toObj(opts?.students);
    const students = Object.fromEntries(objEntries(optStudents).sort( ([i, x],[j, y]) => strCmp(x.classNo, y.classNo) ));
    const SttAll = objKeys(students);
    useEffect(() => {
        const os = opts?.students; 
        if(!isObj(os))return;
        const osis = objKeys(os);
        if(toAry(fields?.studentIds).some(i => osis.includes(i))) return;
        setField('studentIds')(osis);
    }, [opts?.students]);

    if (!vis) return <></>
    return <CpSettingModalTabContainer submitting={0}>
        <CpSettingModalSection>
           <span>
                <CpSettingModalTitle>{t('assignment-settings-year')}</CpSettingModalTitle>
                <CpDropdown className="w100" items={yearItems} idx={year} cb={setField('year')} enable={0} />
           </span><span>
                <CpSettingModalTitle>{t('assignment-settings-class')}</CpSettingModalTitle>
                <CpDropdown className="w100" items={classItems} idx={classId} cb={setField('classId')} />
                {aTErrDiv1(fieldErrs.classId)}
            </span>
        </CpSettingModalSection>
        <CpSettingModalSection>
            <div className={"d-flex flex-column"}>
                <CpSettingModalTitle>{t('assignment-settings-student-list')}</CpSettingModalTitle>
                <CpSettingModalSubTitle>{t("assignment-settings-please-select-students-to-assign")}</CpSettingModalSubTitle>
                {aTErrDiv1(fieldErrs.studentIds)}
                <CpStudentCheckBoxContainer {...{...props, students, allIds:SttAll}} />
            </div>
        </CpSettingModalSection>
    </CpSettingModalTabContainer>
});
export default TabAssignmentSettingClass;

const aryUniStr = (a, glue = '|') => toUniIdAry(a).sort().join(glue);
const IDAryEqual = (a, b) => [a === b] || aryUniStr(a) === aryUniStr(b); 

const CpStudentCheckBoxContainer = (props => {
    const {opts, setOpts, fields, setFields, setField, setTickAry, students, allIds, ticks} = props;
    const [t, uiEn, UILang, setUILang, ut] = useUILang();

    const setTick = setTickAry('studentIds');

    const whole = useMemo(() => isAry(ticks) && isAry(allIds) && (!allIds.some(t => !ticks.includes(t))), [ticks, allIds]); 
    const setWhole = wh => setField('studentIds')(wh? allIds: []);

    const clickAll = e => { stopEvent(e); setWhole(!whole); }    
    const chkAllChange = e => { setWhole(e.currentTarget.checked); } // dont Stop checkbox event or it wont flip

    return <div className={"d-flex w-100 flex-wrap gap-3 border insetShadow p-4"}>
        <div className={"d-flex w-100 gap-2 user-select-none"} role={"button"} onClick={clickAll}>
            <CpCheckbox onChange={chkAllChange} checked={whole} disableOnClick={0} /><span>{t("assignment-settings-whole-class")}</span>
        </div>
        {objEntries(students).map(([sid, student]) => {
            return <CpStudentCheckBox key={sid} {...{whole, student, onClick:setTick(sid), on:ticks.includes(sid)}} />;
        })}
    </div>
});

const CpStudentCheckBox = (props => {
    const {student, on, onClick, whole, ...divProps} = props;
    const [t, uiEn, UILang, setUILang, ut] = useUILang();

    const {classNo, nameEng, nameChi} = student;
    const chkChange = e => onClick(e.currentTarget.checked);
    const click = e => { stopEvent(e); onClick(!on); };
    return <div className={`d-flex gap-3 text-truncate px-3 py-2 semi-bold border user-select-none student-checkbox-btn rounded-light ${
        on? whole? "active": "active": ""}`} {...divProps} style={{width: "300px"}} role={"button"} onClick={click} >
            <CpCheckbox onChange={chkChange} checked={on} disableOnClick={0} />
            <span>{toStr(classNo)}</span><span className={"text-truncate"}>{ut(nameEng, nameChi)}</span>
        </div>
});
