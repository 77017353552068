import { useMemo } from "react";
import { isAry, toAry } from "../../libs/libType";
import { DONUT_RING_COLOR } from "./ChartTypeConst";

const ChartDonut = (props) => { // base on poc DonutChart.tsx
    const {id, line = [], title, subTitle, segments, setting} = props;
    const {ringColor = DONUT_RING_COLOR} = setting || {};

//console.log('ChartDonut', {id, line, segments, title, subTitle, setting});

    const newSegments = useMemo(() => {
        const segs = toAry(segments); 
        const ret = segs.map((segment, idx) => {
            let sum = 0;
            for (let i = 0; i <= idx; i++) {
                sum += segs[i].value;
            };
            return {...segment, value: sum};
        }).reverse();
console.log('ChartDonut newSegments', {ret, segments});
        return ret;
    }, [segments]);

    return <svg width="100%" height="100%" viewBox="0 0 40 40" className="donut">
        <circle className="donut-hole" cx="20" cy="20" r={r} fill="#fff"></circle>
        <circle className="donut-ring" cx="20" cy="20" r={r} fill="transparent"
                strokeWidth={strokeWidth} stroke={ringColor}></circle>

        {toAry(newSegments).map((segment, idx) => 
        <circle key={idx}
            className="donut-segment" fill="transparent"
            cx="20" cy="20" r={r}
            stroke={segment.color}
            strokeWidth={strokeWidth}
            strokeDasharray={`${segment.value} ${100 - segment.value}`}
            strokeDashoffset="0%" transform={`rotate(-90)`}></circle>
        )};

        {toAry(line).map((li, idx) => <g className="marks" stroke={li.color} key={idx}>
            <line x1={r - strokeWidth / 2} y1="0" x2={r + strokeWidth / 2} y2="0" strokeDasharray="0.3" strokeWidth={0.3}
                  style={{transform: `rotate(calc(${((li.value - 25) / baseDeg) * 90}deg))`}}/>
        </g>)
        }

        {title? <g className="ChartDonut donut-text donut-text-1" fill={title.color}>
            <text y="50%" transform="translate(0, 2)">
                <tspan x="50%" textAnchor="middle" className="donut-percent">{title.value}</tspan>
            </text>
            {subTitle? <text y="60%" transform="translate(0, 2)" fill={subTitle.color}>
                <tspan x="50%" textAnchor="middle" className="donut-data">{subTitle.value}</tspan>
            </text>: ''}
        </g>: ''}
    </svg>
};

const r = 15.91549430918954;
const strokeWidth = 3.5;
const baseDeg = 25;

export default ChartDonut;